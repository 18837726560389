// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/pages/wp-preview.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-main-archive-template-js": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/MainArchiveTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-main-archive-template-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-month-archive-template-js": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/MonthArchiveTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-month-archive-template-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-search-template-js": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/SearchTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-search-template-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-single-template-js": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/SingleTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-single-template-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-year-archive-template-js": () => import("./../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/YearArchiveTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-year-archive-template-js" */),
  "component---src-whitespace-gatsby-theme-wordpress-basic-pages-404-js": () => import("./../../../src/@whitespace/gatsby-theme-wordpress-basic/pages/404.js" /* webpackChunkName: "component---src-whitespace-gatsby-theme-wordpress-basic-pages-404-js" */)
}

